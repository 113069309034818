<template>
  <div>
    <loader v-if="isLoading"></loader>
    <div v-else class="align" id="boxNotification">
      <h2>Уведомление о предоставлении услуг третьих лиц</h2>
      <div>

        <p>
          Уважаемый(ая), {{clientInfo.ClientFIO}}, в соответствии с условиями вашего заявления от {{clientInfo.DateZayav}}, оформленного при заключении договора займа от
          {{clientInfo.DateDeal}} № {{clientInfo.DealCode}} вам предоставлена следующая услуга:
        </p>

        <div id="allBoxes">
          <div class="box" v-for="(item, index) in activeBox" :key="index">
            <div class="leftSide">
              <h3>{{item.Print_Title}}</h3>
              <p>{{item.Print_Info}}</p>
              <p>В сумме {{item.Cost}} рублей 00 копеек</p>
            </div>
            <div class="rightSide">
              {{item.Print_Discription}}
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import login from "@/views/auth/login";
export default {
  name: "boxNotifications",
  components: {
    loader: () => import('@/components/other/loader'),
  },
  data() {
    return {
      isLoading: true,
      boxArr: [],
      clientInfo: {}
    }
  },
  methods: {
    getBox() {
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'getBoxArr',
          GUID: this.$route.params.uid
        }).then((res)=>{
          for ( let i = 0; i<res.boxArr.length; i++) {
            console.log(res.boxArr[i].Print_Discription);
            res.boxArr[i].Print_Discription = res.boxArr[i].Print_Discription.split('{*дд.мм.гггг*}').join(res.clientInfo.RejectDate)
          }
          this.boxArr = res.boxArr;
          this.clientInfo = res.clientInfo;
          this.isLoading = false;
        }).catch((res) => {
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
        })
      }
  },
  computed:{
    activeBox(){
      if(typeof this.boxArr === 'object') {
        if (this.boxArr.length > 0) {
          return this.boxArr.filter((item) => item.isActive === 1);
        } else{
          return [];
        }
      } else {
        return [];
      }
    }
  },
  mounted() {
    console.log(document.location)
    this.getBox()
  }
}
</script>

<style scoped>
#boxNotification { display: flex; flex-direction: column; align-items: center }
#boxNotification>div>p { margin: 10px 0 10px 0 }
.align { width: 1085px; margin: 0 auto }
#allBoxes h3 { margin: 0; font-size: 16px }
#allBoxes>.box { display: flex; border: 1px solid black; page-break-inside: avoid; border-bottom: none }
#allBoxes>.box:last-child { border-bottom: 1px solid black }
#allBoxes>.box>.leftSide { border-right: 1px solid black; padding: 10px }
#allBoxes>.box>.rightSide { padding: 10px }
#allBoxes>.box>div { width: 50% }

@media print {
  .align { width: 1075px }
  /*#allBoxes>.box:last-child { border-bottom: 1px solid black }*/
}

</style>